<template>
	<div
		class="min-h-screen bg-gray-800 flex flex-col justify-start py-12 sm:px-6 lg:px-8"
	>
		<div class="sm:mx-auto sm:w-full sm:max-w-md">
			<SalivaIcon class="w-16 mx-auto" />
			<h2 class="mt-6 text-center text-3xl font-extrabold text-gray-100">
				Restore password
			</h2>
			<p class="mt-2 text-center text-sm text-gray-400 max-w">
				Or
				<router-link
					:to="{ name: 'auth.login' }"
					class="font-medium text-indigo-200 hover:text-indigo-500"
				>
					login
				</router-link>
			</p>
		</div>

		<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md" v-if="sent">
			<div
				class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 relative"
			>
				We have emailed you a password reset link. Follow the
				instructions to reset your password
			</div>
		</div>

		<div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md" v-if="!sent">
			<div
				class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 relative"
			>
				<div
					v-if="loading"
					class="absolute inset-0 bg-gray-200 bg-opacity-75 z-50 sm:rounded-lg flex items-center justify-center"
				>
					<Loader class="w-16 mx-auto animate-spin text-gray-500" />
				</div>
				<form @submit.prevent="submit" class="space-y-6">
					<InputWithError
						:error="getError('email')"
						:type="'email'"
						:name="'email'"
						:placeholder="'user@example.com'"
						:label="'Email address'"
						:value.sync="user.email"
					/>

					<div>
						<button
							type="submit"
							class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						>
							Restore password
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import SalivaIcon from '../../assets/svg/saliva_icon.svg';
	import Loader from '../../assets/svg/loader.svg';
	import InputWithError from '../../components/form/InputWithError';
	import { mapActions } from 'vuex';
	export default {
		components: {
			SalivaIcon,
			Loader,
			InputWithError,
		},
		name: 'login',
		mounted() {
			const token = this.$route.query.token;
			if (token) {
				this.$router.push({
					name: 'auth.createPassword',
					params: { token: token },
				});
			}
		},
		methods: {
			...mapActions(['resetPassword']),
			isError(prop) {
				return this.errors[prop] !== undefined;
			},
			getError(prop) {
				return this.isError(prop) ? this.errors[prop][0] : '';
			},
			async submit() {
				this.errors = {};
				this.loading = true;
				try {
					await this.resetPassword(this.user)
						.then((response) => {
							console.log('response', response);
							this.loading = false;
							this.sent = true;
						})
						.catch((e) => {
							console.log(e.response.data);
							this.errors = e.response.data.errors;
							this.loading = false;
						});
					this.showError = false;
				} catch (error) {
					this.showError = true;
				}
			},
		},
		data() {
			return {
				loading: false,
				user: {
					email: null,
				},
				errors: {},
				sent: false,
			};
		},
	};
</script>
