<template>
	<div class="h-screen flex overflow-hidden bg-gray-100">
		<!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->

		<div class="flex flex-col w-0 flex-1 overflow-hidden">
			<div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
				<div class="flex-1 px-4 flex justify-between">
					<div class="flex-1 flex"><SalivaIcon class="w-10" /></div>
					<div class="ml-4 flex items-center md:ml-6">
						<ProfileDropdown>
							<LogoutButton
								class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
								role="menuitem"
								>Sign out</LogoutButton
							>
						</ProfileDropdown>
					</div>
				</div>
			</div>
			<slot></slot>
		</div>
		<Toast />
	</div>
</template>
<script>
	import ProfileDropdown from '../components/ProfileDropdown';
	import LogoutButton from '../components/LogoutButton';
	import Toast from '../components/tailwindui/toast';
	import { mapMutations } from 'vuex';
	import SalivaIcon from '../assets/svg/saliva_icon.svg';
	export default {
		name: 'LayoutIsAuth',
		methods: {
			...mapMutations(['setMobileNavWrapper']),
		},
		components: {
			ProfileDropdown,
			LogoutButton,
			SalivaIcon,
			Toast,
		},
	};
</script>
