<template>
	<div class="ml-3 relative">
		<div class="">
			<button
				@click="user_menu = !user_menu"
				class="flex items-center max-w-xs bg-white flex items-center text-sm rounded-l-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				id="user-menu"
				aria-haspopup="true"
			>
				<span class="sr-only">Open user menu</span>

				<div class="ml-4 text-left">
					<div class="text-sm text-gray-700 leading-none">
						{{ User ? User.name : '' }}
					</div>
					<div class="text-xs text-gray-500 leading-none mt-1">
						{{ Business ? Business.name : '' }}
					</div>
				</div>
			</button>
		</div>
		<transition
			enter-active-class="transition ease-out duration-100"
			enter-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75"
			leave-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95"
		>
			<div
				v-if="user_menu"
				class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
				role="menu"
				aria-orientation="vertical"
				aria-labelledby="user-menu"
			>
				<slot></slot>
			</div>
		</transition>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex';
	export default {
		name: 'ProfileDropdown',
		computed: {
			...mapGetters(['User', 'Business']),
		},
		data() {
			return {
				user_menu: false,
			};
		},
	};
</script>
>
