<template>
	<div id="app">
    <slot></slot>
	</div>
</template>

<script>


export default {
	name: 'NotAuthenticatedLayout',
};
</script>


