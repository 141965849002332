<template>
	<div class="mt-6">
		<div class="relative">
			<div class="absolute inset-0 flex items-center">
				<div class="w-full border-t border-gray-300"></div>
			</div>
			<div class="relative flex justify-center text-sm">
				<span class="px-2 bg-white text-gray-500">
					More information
				</span>
			</div>
		</div>

		<div class="mt-6 grid grid-cols-3 gap-3">
			<div>
				<a
					href="https://salivapod.com"
					target="_blank"
					class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
				>
					<span class="sr-only">Salivapod</span>
					Salivapod
				</a>
			</div>

			<div>
				<a
					href="https://apps.apple.com/app/id1534544519"
					target="_blank"
					class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
				>
					<span class="sr-only">appstore</span>
					Appstore
				</a>
			</div>

			<div>
				<a
					href="https://play.google.com/store/apps/details?id=no.iotek.salivacode"
					target="_blank"
					class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
				>
					<span class="sr-only">Google play</span>
					Google play
				</a>
			</div>
		</div>
	</div>
</template>
<script>
	export default {};
</script>
