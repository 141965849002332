<template>
    <component :is="component"><router-view/></component>
</template>

<script>
// eslint-ignore
import {mapGetters} from 'vuex'
export default {
	name: 'App',
  computed: {
    ...mapGetters(['isAuthenticated']),
    component() {
      return this.isAuthenticated ? 'layoutIsAuth' : 'layoutNotAuth';
    }
  }
};
</script>


