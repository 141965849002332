import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);
import Router from '../router';

const state = {
	user: null,
	business: null,
	cohorts: null,
	access_token: null,
	site: {
		mobile_nav: {
			wrapper: false,
			inner: false,
		},
	},
	errors: [],
};

const actions = {
	async register({ commit }, data) {
		let response = await axios.post('api/v1/medical/auth/user', data);
		await commit('setUser', response.data.data.user);
		await commit('setToken', response.data.access_token);
	},
	/* eslint-disable-next-line */
	async resetPassword({ commit }, data) {
		await axios.post('api/v1/medical/auth/reset-password', data);
	},
	/* eslint-disable-next-line */
	async createPassword({ commit }, data) {
		let response = await axios.post(
			'api/v1/medical/auth/create-password',
			data
		);
		await commit('setUser', response.data.data.user);
		await commit('setToken', response.data.access_token);
		Router.push('/');
	},
	async logout({ commit, getters }) {
		if (getters.isAuthenticated) {
			await axios
				.post('api/v1/medical/auth/logout')
				.then(() => {
					let user = null;
					commit('clearUser', user);
				})
				.catch(() => {
					let user = null;
					commit('clearUser', user);
				});
		}
		return Router.push('/login');
	},
	async login({ commit }, User) {
		let response = await axios.post('api/v1/medical/auth/login', User);
		await commit('setUser', response.data.data.user);
		await commit('setToken', response.data.access_token);
	},
	async me({ commit }) {
		let response = await axios.get('api/v1/medical/me');
		await commit('setUser', response.data.data.user);
	},
};

const mutations = {
	setUser(state, user) {
		state.user = user;
	},
	setMobileNavWrapper(state, current) {
		state.site.mobile_nav.wrapper = current;
	},
	setMobileNavInner(state, current) {
		state.site.mobile_nav.inner = current;
	},
	setToken(state, token) {
		state.access_token = token;
	},
	setCohorts(state, cohorts) {
		state.cohorts = cohorts;
	},
	setBusiness(state, business) {
		state.business = business;
	},
	clearUser(state) {
		state.user = null;
		state.business = null;
		state.cohorts = null;
		state.access_token = null;
	},
};

const getters = {
	isAuthenticated: state => !!state.user,
	User: state => state.user,
	Business: state => state.business,
	Cohort: state => state.cohorts,
	mobile_nav: state => state.site.mobile_nav,
};

export default new Vuex.Store({
	plugins: [createPersistedState()],
	state,
	actions,
	mutations,
	getters,
});
